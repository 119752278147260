import { template as template_e988eff9718143748cac20ee12bf3598 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e988eff9718143748cac20ee12bf3598(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
