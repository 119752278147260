import { template as template_019b1390d1c949519ff73024d481659b } from "@ember/template-compiler";
const FKLabel = template_019b1390d1c949519ff73024d481659b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
