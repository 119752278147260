import { template as template_8295b7b410e244369cb5689ce2f55ec8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8295b7b410e244369cb5689ce2f55ec8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
