import { template as template_d44923f15bd54417baa8f87ad336e700 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d44923f15bd54417baa8f87ad336e700(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
