import { template as template_cf4c1d0fcfa54468946e9de8c8b02018 } from "@ember/template-compiler";
const FKText = template_cf4c1d0fcfa54468946e9de8c8b02018(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
