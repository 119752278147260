import { template as template_7227bb1b2ca249dba44ed3df079c94ab } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const GroupNotificationsTracking = template_7227bb1b2ca249dba44ed3df079c94ab(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="groups.notifications"
    class="group-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupNotificationsTracking;
