import { template as template_e3bdf460a24243a9914efc7f0046108e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_e3bdf460a24243a9914efc7f0046108e(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
